import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetComicAxios } from "../Appcontrollers/comicXciosClient";

export const useHookAnimated = () => {
    const [genre, setGenre] = useState(null)
    const [consonant, setConsonant] = useState(null)
    const [filterDate, setFilterDate] = useState(null)
    const [plate, setPlate] = useState(null);
    const [page, setPage] = useState(1)

    const [pageCount, setPageCount] = useState()
    const fetchMovies = async (page, filterDate,  genre, consonant, plate) => {
        try {
            const response = await GetComicAxios.post(`movies?page=${page}&genre=4&itemsPerPage=18`);
            setPageCount(response.data.last_page)
            return response.data.data
          } catch (error) {
            // Handle errors here
            console.error('Error:', error);
            throw error;
          }
    }

    const onSuccess = (data) => {
        console.log("fetch succeeded!", data.data)
    }

    const onError = (error) => {
        console.log("fetch failed!", error)
    }
    
    const { data, isLoading, error, isError, refetch } = useQuery(
        {
            queryKey: ["comic", page, genre, filterDate, consonant, plate],
            queryFn: () => fetchMovies(page, filterDate,  genre, consonant, plate),
            staleTime: 1000,
            onSuccess: onSuccess,
            onError: onError,
        }
    )

    return {
            data,
            isLoading, 
            error, 
            setPage, 
            filterDate, 
            setFilterDate, 
            setGenre, 
            consonant,
            setConsonant, 
            genre, 
            pageCount, 
            plate, 
            setPlate,
            refetch
        }
}