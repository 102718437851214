import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetComicAxios } from "../Appcontrollers/comicXciosClient";

export const UseHookEntertainment = () => {
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [search, setSearch] = useState(null)
    const [moviesFilters, setMoviesFilters] = useState(null)
    const [moviesGenre, seMoviesGenre] = useState(5);
    const fetchEnterTainment = async (page, moviesFilters, search, moviesGenre) => {
        try {
            const response = await GetComicAxios.post(`movies?page=${page}${moviesGenre? `&genre=${moviesGenre}`:''}&itemsPerPage=18`,)
            setPageCount(response.data.last_page)
            return response.data.data;
          } catch (error) {
            console.error('Error:', error);
            throw error;
          }
    }

    const onSuccess = (data) => {
        //console.log("fetch succeeded!", data.data)
    }

    const onError = (error) => {
        //console.log("fetch failed!", error)
    }
    
    const { data, isLoading, error, isError, refetch } = useQuery(
        {
            queryKey: ["korean", page, moviesFilters, search],
            queryFn: () => fetchEnterTainment(page, moviesFilters, search, moviesGenre),
            staleTime: 1000,
            onSuccess: onSuccess,
            onError: onError,
        }
    )

    return {
            data, 
            isLoading, 
            error,
            moviesFilters, 
            search, 
            moviesGenre,
            pageCount, 
            seMoviesGenre,
            setSearch, 
            setMoviesFilters, 
            setPage, 
            refetch
        }
}