import { Link } from "react-router-dom";
import rezinLogo from "../../asset/img/rezin.png";
import { genresContext } from "../../Appcontrollers/useHookGenre";
import { useContext } from "react";
import { plateContext } from "../../Appcontrollers/plate";
import { durateContext } from "../../Appcontrollers/DateProvider";
import { useDetailPop } from "../../Appcontrollers/videoDetail";

const ComicCard = ({
  id,
  title,
  poster,
  year,
  genreId,
  posting_titme,
  ep,
  disciption,
  durateId,
}) => {
  const { showPopup } = useDetailPop();
  const { data: genres } = useContext(genresContext);
  const { plate: plates } = useContext(plateContext);
  const { durate: durates } = useContext(durateContext);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2); // Get the last two digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month in two-digit format
    const day = date.getDate().toString().padStart(2, "0"); // Get the day in two-digit format

    return `${year}${month}${day}`; // Concatenate the components
  };

  return (
    <Link
      // Assuming you want to navigate to a details page
      onClick={() =>
        showPopup(title, id, ep, poster, disciption, genreId, year)
      }
    >
      <li
        className="comic_item_card p-3 cursor-pointer text-white"
        style={{ borderRadius: "20px" }}
      >
        <div className="post-image relative border border-solid-3 rounded-lg hover:opacity-80">
          <ul className="flex flex-row justify-around flex-wrap items-center absolute left-0">
            {/* {plates?.map((p) =>
              p.id === plateId ? (
                <li
                  className="py-1 px-1 bg-lime-400 mr-1 rounded-md"
                  key={p.id}
                >
                  <img
                    src={
                      p.flag.startsWith("images/")
                        ? "http://kotv-001.com/" + p.flag
                        : p.flag
                    }
                    className="w-5"
                    style={{
                      boxShadow: "-2px 5px 20px 5px rgb(0 0 0 / 66%)",
                      borderRadius: "20px",
                    }}
                    alt={p.name}
                  />
                </li>
              ) : null
            )} */}

            {genres?.map((genre) =>
              genre.id === genreId ? (
                <li
                  className="py-1 px-1 bg-red-400 text-xs mr-1 rounded-md"
                  key={genre.id}
                >
                  {genre.name}
                </li>
              ) : null
            )}

            {durates?.map((d) =>
              d.id === durateId ? (
                <li
                  className="py-1 px-1 bg-red-400 text-xs mr-1 rounded-md"
                  key={d.id}
                >
                  {d.name}
                </li>
              ) : null
            )}
          </ul>
          <div className="relative">
            {posting_titme && (
              <button className="px-3 py-1 bg-red-400 rounded-full absolute -top-4 left-1/2 -translate-x-1/2">
                {posting_titme}
              </button>
            )}
            <img
              src={poster}
              title={title}
              alt={title} // Provide an alt attribute for accessibility
              className="h-64 w-full"
              onError={(e) => {
                // Handle image load errors
                e.target.src =
                  "https://newtoki330.com/data/file/webtoon/6571f38409fb8_2tPR9J0s_6f158e7fa7b44e71c917830b367a20d641ff9a58.jpg"; // Replace with path to a placeholder image
                e.target.onerror = null; // Prevent infinite loop in case placeholder image fails to load
              }}
              style={{
                // boxShadow: "-3px 5px 40px 5px rgb(0 0 0 / 66%)",
                borderRadius: "7px",
              }}
            />
            {(genreId === 1 || genreId === 5) && (
              <div
                className="absolute bottom-0 left-0 w-full py-2 px-2 bg-emerald-950 text-center"
                style={{
                  borderBottomLeftRadius: "7px",
                  borderBottomRightRadius: "7px",
                }}
              >
                <span>제{ep}회</span>
                <span className="text-white text-sm"> {formatDate(year)}</span>
              </div>
            )}
          </div>
        </div>
        <div className="post_dcr_wrapper">
          <p className="text-center text-black py-2 px-2">{title}</p>
        </div>
      </li>
    </Link>
  );
};

export default ComicCard;
