import React from "react";

import { createBrowserRouter, Navigate } from "react-router-dom";

import Index from "./Layout/Index";
import Login from "./pages/Login";
import Auth from "./Layout/Auth";
import Home from "./pages/Home";
import { Path } from "./config/Path";
import { PageNotFound } from "./pages/404/index";

import Entertainment from "./pages/entertainment";
import Movies from "./pages/movies";
import Drama from "./pages/korean_drama";
import Animated from "./pages/animated";
import MovieView from "./pages/view";
import InterNationMovies from "./pages/internactional";
const message = "404";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
    //loader: rootLoader,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/entertainment",
        element: <Entertainment />,
      },
      {
        path: "/korean-movies",
        element: <Movies />,
      },
      {
        path: "/international-movies",
        element: <InterNationMovies />,
      },
      {
        path: "/animated-film",
        element: <Animated />,
      },
      {
        path: "/drama",
        element: <Drama />,
      },
      // {
      //   path: "/korean drama",
      //   element: <Movies />,
      // },
      {
        path: "/view/:id/:title/:ep",
        element: <MovieView />,
      },
    ],
  },
  {
    path: "/",
    element: <Auth />,
    children: [
      {
        path: "/",
        element: <Navigate to={"/login"} />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/*",
    element: <PageNotFound message={message} />,
  },
]);

export default routers;
