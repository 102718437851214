
import FetchingDataError from "../fetchingError/Index";

import ComicCard from "../comicCard/Index";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useDetailPop } from "../../Appcontrollers/videoDetail";
import { useEffect } from "react";
export const Search = ({
    data, 
    isLoading, 
    error, 
    refetch,
    setPage,
    pageCount
}) => {
const { popupData,showPopup } = useDetailPop();
useEffect(()=> {

},[popupData.isVisible])
  const renderSearchData = () => {
    if(isLoading){
        return(
            <div className='loding-wrapper fixed top-2/4 left-2/4 -translate-x-2/4 translate-y-2/4'> 
                <span className="loader"></span> 
            </div>
        )
    }
    if(error) {
        return(
            <FetchingDataError refetch={refetch} />
        )
    }
    if(data.length === 0) {
        return(
            <div className=" flex flex-col justify-center items-center py-4">
                <lord-icon
                    src="https://cdn.lordicon.com/xumlwjxf.json"
                    trigger="loop"
                    delay="1500"
                    colors="primary:#e83a30,secondary:#e83a30,tertiary:#faddd1"
                    style={{width:"250px",height:"250px"}}>
                </lord-icon>
                <h1 className="text-5xl uppercase  text-red-500 font-extrabold py-5">Search No Found</h1>
                <h1 className="text-8xl uppercase  text-red-500 font-extrabold py-5">404</h1>
                <button className="border border-solid border-spacing-2 px-2 py-4 rounded-md border-white">
                    <span>
                    <FontAwesomeIcon icon={faArrowAltCircleLeft}  className="px-2 text-2xl text-red-600"/>
                    </span>
                    <Link to={'/'} className=" whitespace-nowrap text-2xl pr-4 font-medium text-white">Back Home</Link>
                </button>
            </div>
            
        )
    }
    
    return(
        <ul className="comic_items_wrapper w-full  overflow-y-auto">
            { data?.map(movie => (
                   <li onClick={() => showPopup(
                                        movie.title, 
                                        movie.title_id, 
                                        movie?.number_ep? movie.number_ep : 0, 
                                        movie.image, 
                                        movie.des, 
                                        movie.cate_id, 
                                        movie.release_date
                                        )} 
                        className="flex hover:text-blue-400 flex-row mt-3 border-b-2 border-black items-center justify-start gap-3 cursor-pointer">
                        <img src={movie.image}  className=" w-16"/>
                        <h3>{movie.title}</h3>
                   </li>
                        
                 ))
            }
        </ul>
    )
  }



  return(
    <div className="fixed right-0 top-8 w-full sm:w-96 overflow-y-auto bg-white min-h-screen max-h-screen z-40 px-12 py-16">
         { renderSearchData() }       
    </div>
  )
}