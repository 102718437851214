import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./style.css";
const Epesod = ({ title, title_id, current_ep }) => {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEpisodes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}movie/episodes`,
          {
            params: { title_id },
            headers: {
              "Content-Type": "application/json",
              "api-key": process.env.REACT_APP_API_KEY,
            },
          }
        );
        setEpisodes(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchEpisodes();
  }, [title_id]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="ep_container">
      <ul className="ep_wrapper grid grid-cols-3 sm:grid-cols-4 lg:grid-cols-10 gap-2 lg:gap-4">
        {episodes.map((episode) => (
          <li
            key={episode.id}
            className={`px-3 py-1 ${
              episode.number_ep === current_ep ? "bg-red-400" : "bg-blue-950"
            }`}
          >
            <Link to={`/view/${episode.title_id}/view/${episode.number_ep}`}>
              제 {episode.number_ep} 화
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Epesod;
