import React from "react";
import Pagination from "../../components/pageGinetion";
import ComicCard from "../../components/comicCard/Index";
import FetchingDataError from "../../components/fetchingError/Index";

import { useHookDrama } from "../../hook/useHookDrama";

function Drama() {
  const { data, isLoading, error, setPage, pageCount, refetch } =
    useHookDrama();

  const renderMovies = () => {
    if (isLoading) {
      return (
        <div className="loading-wrapper fixed top-2/4 left-2/4 -translate-x-2/4 translate-y-2/4">
          <span className="loader"></span>
        </div>
      );
    }

    if (error || data?.length === 0) {
      return <FetchingDataError refetch={refetch} />;
    }

    const getStartAndEndOfWeek = () => {
      const now = new Date();
      const start = new Date(now);
      start.setDate(now.getDate() - now.getDay() + 1); // Start of week (Monday)
      start.setHours(0, 0, 0, 0); // Set time to start of the day

      const end = new Date(start);
      end.setDate(start.getDate() + 6); // End of week (Sunday)
      end.setHours(23, 59, 59, 999); // Set time to end of the day

      return { start, end };
    };

    const isWithinCurrentWeek = (date) => {
      const { start, end } = getStartAndEndOfWeek();
      const movieDate = new Date(date);
      return movieDate >= start && movieDate <= end;
    };

    return (
      <>
        <ul className="comic_items_wrapper grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-1">
          {data?.map((movie) => {
            const postingTime = isWithinCurrentWeek(movie.latest_ep_date)
              ? "new"
              : null;

            return (
              <ComicCard
                key={movie.title_id}
                id={movie.title_id}
                title={movie.title}
                poster={movie.image}
                year={movie.release_date}
                genreId={movie.cate_id}
                posting_titme={postingTime} // Pass the posting time to ComicCard
                ep={movie?.number_ep ? movie.number_ep : 0}
                disciption={movie.des} // Fixed typo from 'disciption' to 'description'
                durateId={movie.Id_durate}
              />
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <div className="comic-card-wrapper">
      {renderMovies()}
      <Pagination setPage={setPage} pageCount={pageCount} />
    </div>
  );
}

export default Drama;
