import React, { useEffect, useRef, useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faArrowCircleLeft,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Menus from "../data/Menu";
import Logo from "../asset/img/logo.png";
import { genresContext } from "../Appcontrollers/useHookGenre";
import { useDetailPop } from "../Appcontrollers/videoDetail";

function Navbar({ onMenuSwich, handlefilterBy, token, user, setSearch }) {
  const location = useLocation();
  const { popupData, showPopup } = useDetailPop();
  const isDetailPage = location.pathname.includes("/detail");
  const isview = location.pathname.includes("/view");
  const currentPath = location.pathname;
  const [cancelButon, setCancelButton] = useState(
    !isDetailPage && !isview ? false : false
  );
  const [mobleMenu, setMobleMenu] = useState(false);
  const navigate = useNavigate();
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [filterActive, setFilterActive] = useState(true);
  const [searBtnHandle, setSearBtnHandle] = useState("sh-btn-inactive");
  const [setActivFillter, setFilterActivClass] = useState("filterOut");
  const searchValue = useRef(null);
  const mobileMenuRef = useRef(null);

  const { data } = useContext(genresContext);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setMobleMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [mobileMenuRef]);

  useEffect(() => {
    if (popupData.isVisible) {
      handleCancelInput();
    }
  }, [popupData.isVisible]);

  useEffect(() => {
    const menueWrapper = document.querySelector(".menue_wrapper");
    if (menueWrapper) {
      menueWrapper.addEventListener("mousedown", handleMouseDown);
      menueWrapper.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      if (menueWrapper) {
        menueWrapper.removeEventListener("mousedown", handleMouseDown);
        menueWrapper.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, []);

  const MobleMenueShow = () => {
    setMobleMenu(!mobleMenu);
  };

  const searBtnHandleBnt = () => {
    if (searBtnHandle === "sh-btn-inactive") {
      setSearBtnHandle("sh-btn-active");
    } else {
      setSearBtnHandle("sh-btn-inactive");
    }
  };

  const handleMouseDown = (e) => {
    setDragging(true);
    setStartX(e.pageX - document.querySelector(".menue_wrapper").offsetLeft);
  };

  const handleActivFilter = (e) => {
    if (!filterActive) {
      setFilterActive(true);
      setFilterActivClass("fileterActive");
    } else {
      setFilterActive(false);
      setFilterActivClass("filterOut");
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    const x = e.pageX - document.querySelector(".menue_wrapper").offsetLeft;
    const walk = (x - startX) * 20; // Adjusting the scrolling speed
    document.querySelector(".menue_wrapper").scrollLeft -= walk;
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleSwichMenu = (menue_link) => {
    navigate(menue_link);
    handlefilterBy(false, null);
    setMobleMenu(false);
  };

  const handleBack = (e) => {
    e.preventDefault(); // Prevent default behavior of the event
  };

  const handleSearch = () => {
    onMenuSwich(searchValue.current.value);
    if (searchValue.current.value !== "") {
      setSearch(true);
      setCancelButton(true);
    } else {
      setCancelButton(false);
      setSearch(false);
    }
  };

  const handleCancelInput = () => {
    searchValue.current.value = "";
    setCancelButton(false);
    setSearch(false);
  };

  if (isDetailPage && isview) {
    setSearch(false);
  }

  const Logout = (token) => {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("PERSONACCESS_IN");
    window.location.reload();
  };

  const handlerequestCatoon = (bool) => {
    bool
      ? navigate("/catoon request")
      : alert("Sorry you can not acces this without login");
  };

  return (
    <div className="menu-wrapper px-3 xl:px-0 shadow-sm bg-white">
      <ul
        className={`${
          mobleMenu ? "lg:px-0" : "xl:px-0"
        } gap-3 lg:gap-0 bg-white py-2 h-16 flex flex-row justify-between lg:justify-start items-center max-w-screen-2xl mx-auto`}
      >
        <li className="ml-4 lg:ml-0">
          <button
            className="site-header__offcanvas--toggler lg:pr-1.5 navbar-toggler"
            data-toggle="offcanvas"
            onClick={MobleMenueShow}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13">
              <path d="M0 13L0 11.4 16 11.4 16 13 0 13ZM0 5.7L16 5.7 16 7.3 0 7.3 0 5.7ZM0 0L16 0 16 1.6 0 1.6 0 0Z"></path>
            </svg>
          </button>
        </li>
        <li>
          <Link to={"/"}>
            <img src={Logo} alt="Logo" className="h-12 w-96 pl-2" />
          </Link>
        </li>
        <li className="desktop md:block">
          <ul className="px-4 flex flex-row flex-wrap md:flex-nowrap justify-between xl:flex-nowrap items-center">
            {Menus.menuList.map((Element, index) => (
              <li
                className={`
                                        cursor-pointer py-1 px-5 font-bold text-1xl text-nowrap
                                        ${
                                          currentPath === Element.path
                                            ? "text-red-500"
                                            : "text-black"
                                        }`}
                key={index}
                onClick={() => handleSwichMenu(Element.path)}
              >
                {Element.menu_name}
              </li>
            ))}
            <li className="cursor-pointer py-1 px-5 font-bold text-1xl text-nowrap">
              <Link to={"https://t.me/linksto09"} target="blank">
                문의
              </Link>
            </li>
          </ul>
        </li>
        <li className="lg:w-full">
          <ul className="flex w-full flex-row items-center justify-center md:justify-end overflow-x-hidden">
            <li
              className={`shearbutton bg-red-400 mr-2 p-0.5 min-w-40 rounded-full z-10`}
            >
              <input
                type="text"
                placeholder="제목을 입력하세요"
                onChange={(e) => handleSearch()}
                ref={searchValue}
                className={`bg-white h-max w-full rounded-full pl-3 pr-10 py-2 outline-none`}
              />
            </li>
            <li className="bg-red-400 mr-3 p-2 rounded-full z-20">
              {!cancelButon ? (
                <button
                  className="h-6 w-6 flex flex-row items-center justify-center"
                  onClick={searBtnHandleBnt}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-white font-bold text-2xl"
                  />
                </button>
              ) : (
                <button
                  className="h-6 w-6 flex flex-row items-center justify-center"
                  onClick={handleCancelInput}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="text-white font-bold text-2xl"
                  />
                </button>
              )}
            </li>
          </ul>
        </li>
      </ul>
      {mobleMenu && (
        <div
          className="shadow-md fixed left-0 top-0 bg-white h-screen"
          ref={mobileMenuRef}
        >
          <button
            onClick={MobleMenueShow}
            className=" absolute -top-2 -left-1 bg-blue-400 text-white w-8 h-8 rounded-full"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <ul className="min-w-56 pt-4">
            {Menus.menuList.map((Element, index) => (
              <li
                className={`
                                        cursor-pointer py-2 px-5 font-bold text-1xl text-nowrap
                                        ${
                                          currentPath === Element.path
                                            ? "text-red-500"
                                            : "text-black"
                                        }`}
                key={index}
                onClick={() => handleSwichMenu(Element.path)}
              >
                {Element.menu_name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Navbar;
