import React, { useState, useEffect, useContext } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faHeart,
  faLandMineOn,
  faSearch,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import Epesod from "../../components/ep/epoesond";
import { ActorSlider } from "../../components/Artor_slider";
import { useHookView } from "../../hook/useHookDetail";
import { useStateContext } from "../../Appcontrollers/ContextProvider";
import VideoPlayer from "../../components/videoplayer/videoPlayer";
import { useDetailPop } from "../../Appcontrollers/videoDetail";
const MovieView = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { token } = useStateContext();
  const fullUrl = `${window.location.origin}${currentPath}`;
  const { data: movieData, isLoading, error, refetch } = useHookView();
  const [addBookMark, setBookMark] = useState(false);
  const [fevorate, setFevorate] = useState(false);
  const [report, setReport] = useState(false);
  const [fevorateCount, setFevorateCount] = useState(0);
  const [filter, setFilter] = useState("");
  const { hidePopup } = useDetailPop();
  useEffect(() => {
    hidePopup();
  }, [currentPath]);
  useEffect(() => {
    const swiper = new Swiper(".swiper-free-start", {
      freeMode: true,
      slidesPerView: "auto",
      spaceBetween: 0,
    });

    const movieFrame = document.getElementById("movie-frame");
    if (movieFrame) {
      movieFrame.onload = () => {
        setTimeout(() => {
          movieFrame.style.display = "block";
        }, 1000);
      };
    }
  }, []);

  const handleAddbookMark = (status) => {
    setBookMark(status);
  };

  const handleFevorate = (status) => {
    setFevorateCount(
      !status && fevorateCount > 0 ? fevorateCount - 1 : fevorateCount + 1
    );
    setFevorate(status);
  };

  const handleReport = (status) => {
    setReport(status);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };
  const executeGood = (href, setCount) => {
    axios
      .post(href, { js: "on" })
      .then((res) => {
        if (res.data.error !== 0) {
          alert(res.data.message);
        } else if (res.data.count) {
          setCount(res.data.count);
          alert("이 글을 추천하였습니다.");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  let actors = [];
  if (typeof movieData?.actor === "string") {
    if (
      movieData.actor.trim().startsWith("[") &&
      movieData.actor.trim().endsWith("]")
    ) {
      try {
        actors = JSON.parse(movieData.actor);
      } catch (error) {
        actors = [];
      }
    } else {
      // handle case where actor data is not a valid JSON string
    }
  } else if (Array.isArray(movieData?.actor)) {
    actors = movieData.actor;
  }

  const handleShare = () => {
    const shareOptions = `
      width=600,
      height=400,
      left=${window.innerWidth / 2 - 300},
      top=${window.innerHeight / 2 - 200}
    `;

    const shareWindow = window.open("", "", shareOptions);
    shareWindow.document.write(`
      <html>
        <head>
          <title>${process.env.REACT_APP_NAME}/${fullUrl}</title>
        </head>
        <body style="background-color:black;">
          <ul style="width:100%; display:flex; flex-direction:row; align-items:center; justify-content:space-around;">
          <li>
            <a class="px-2 py-2 rounded-full bg-blue-600" href="https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
              fullUrl
            )}" target="_blank">Facebook</a></li>
            <li><a href="https://twitter.com/intent/tweet?url=${encodeURIComponent(
              fullUrl
            )}&text=Check out this awesome page!" target="_blank">Twitter</a></li>
            <li><a href="#" onclick="alert('Instagram does not support direct sharing. Please copy the link: ${fullUrl}')">Instagram</a></li>
          </ul>
        </body>
      </html>
    `);
  };

  return (
    <div className="content flex flex-col items-center justify-center max-w-7xl mx-auto">
      <div className="player"></div>
      <div id="movie-container" className="w-full">
        <div className="movie-view w-full">
          {!movieData?.bunny_url ? (
            <div
              style={{
                color: "red",
                width: "100%",
                fontWeight: "600",
                height: "450px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid gray",
              }}
              className="bg-black"
            >
              <img src={require("../../asset/img/loading_setting_no_bg.gif")} />
            </div>
          ) : (
            <div className="movie-player w-full">
              <VideoPlayer
                src={`${movieData?.bunny_url}`}
                epesod={movieData?.number_ep}
              />
              {/* // <VideoPlayer src='https://vz-092ce06c-787.b-cdn.net/13249915-79e2-430b-86bf-2846120666fc/playlist.m3u8' /> */}
            </div>
          )}
          <div className="content-banner"></div>
          {/* <div className="movie-header  mt-3">
            <div className="movie-utility">
              <button
                onClick={handleShare}
                className="px-2 py-1 bg-blue-400 text-white"
              >
                <FontAwesomeIcon icon={faShareNodes} className="icons" />
                <span>알림</span>
              </button>
            </div>
          </div> */}
          <div className="movie-posted  px-3">
            <h1 style={{ padding: "20px 0" }}>{movieData?.title}</h1>
            <div className="posted w-full">
              <div className="summary">
                <div className="overview mb-5">
                  <p>{movieData?.des && movieData?.des}</p>
                </div>
                <b className="tag">
                  {movieData?.author && "감독: " + movieData?.author}
                </b>
                {/* {Array.isArray(actors) && actors.length > 0 ? (
                  <ActorSlider actors={movieData?.actor_images} />
                ) : (
                  <span className="tag" style={{ color: "white" }}>
                    {movieData?.actor}
                  </span>
                )} */}
                {/* <div className='bg-blue-300'>
                  {Array.isArray(actors) && actors.length > 0 ? (
                    <div className='w-96 '>
                      <ActorSlider actors={movieData?.actor_images} />
                    </div>
                  ) : (
                    <span className="tag" style={{ color: 'white' }}>{movieData?.actor}</span>
                  )}
                </div> */}
              </div>
            </div>
            {movieData?.number_ep && movieData?.number_ep >= 1 ? (
              <Epesod
                title_id={movieData?.title_id}
                current_ep={movieData?.number_ep}
              />
            ) : (
              ""
            )}

            {Array.isArray(actors) && actors.length > 0 ? (
              <ActorSlider actors={movieData?.actor_images} />
            ) : (
              <span className="tag" style={{ color: "white" }}>
                {movieData?.actor}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieView;
