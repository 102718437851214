import axios from "axios";

export const GetComicAxios = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "api-key":
      "pdhzCBjiwGD9a0ZFVjkIm00Adl95rGU9PobVI9XYzE8U2374vuL80ROgDl220XkchjWPxutITxFkDqsGdVYycDqBsGE9KOMTzOptXFZv8ZFJDpP2OjYbuHa8pHIUQbb7UITA3YpYOGwKGSSLlWYzRmAbhXcJ1hOtttdaYBJlBBc7UkkTuEVQAdpmjb341x",
  },
});

export const AuthAxiosApi = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
});
