import React, { useRef, useEffect, useState } from "react";
import Hls from "hls.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const VideoPlayer = ({ src, epesod }) => {
  const videoRef = useRef(null);
  const [error, setError] = useState(false);
  useEffect(() => {
    let hls;

    const handleError = (data) => {
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            console.error("Network error:", data);
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error("Media error:", data);
            break;
          default:
            console.error("Other error:", data);
            break;
        }
        setError(true);
      }
    };

    if (videoRef.current) {
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.ERROR, handleError);
      } else if (videoRef.current.canPlayType("application/x-mpegURL")) {
        videoRef.current.src = src;
        videoRef.current.addEventListener("error", () => setError(true));
      } else {
        setError(true);
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [src]);

  return (
    <div className="flex flex-col items-center justify-center bg-black w-full">
      <div
        className={`flex flex-row items-center justify-center w-full px-1 w-full`}
      >
        {/* {epesod > 0 &&
        <button type='button' className='px-4 bg-blue-400 py-2 text-white'>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} />
        </button>
        } */}
        {error ? (
          <div style={{ color: "red", textAlign: "center" }}>
            Video has been removed or not found!
          </div>
        ) : (
          <video
            ref={videoRef}
            loading="lazy"
            controls
            className="w-full h-full"
            preload="metadata"
            controlsList="nodownload"
            tabIndex="-1"
          >
            Your browser does not support the video tag.
          </video>
        )}
        {/* {epesod > 0 &&
          <button type='button' className='px-4 bg-blue-400 py-2 text-white'>
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </button>
        } */}
      </div>
    </div>
  );
};

export default VideoPlayer;
