// PopupContext.js
import React, { createContext, useState, useContext } from 'react';

// Create a Context for the popup
const VideoPopUpProp = createContext();

// Create a provider component
export const PopupProvider = ({ children }) => {
  const [popupData, setPopupData] = useState({ title:'', id:'', ep:'', poster:'', disciption:'', genreId:'', year:'', isVisible: false });
  
  const showPopup = (title, id, ep, poster, disciption, genreId, year) => {
    setPopupData({title, id, ep, poster, disciption, genreId, year, isVisible: true });
  };

  const hidePopup = () => {
    setPopupData({ ...popupData, isVisible: false });
  };

  return (
    <VideoPopUpProp.Provider value={{ popupData, showPopup, hidePopup }}>
      {children}
    </VideoPopUpProp.Provider>
  );
};

// Custom hook to use the VideoPopUpProp
export const useDetailPop = () => {
  return useContext(VideoPopUpProp);
};
