import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../App.css";
import { useState } from "react";
import "../css/error.css";
import { useStateContext } from "../Appcontrollers/ContextProvider";
import Oop from "../components/Oop";
import { Search } from "../components/Search/index";
import { useHookSearch } from "../hook/useSearch";
import Footer from "../components/foodter";
import DetailVideo from "../components/videoDetail";
import { useDetailPop } from "../Appcontrollers/videoDetail";

function Index() {
  const { popupData } = useDetailPop();
  const { user, token, oopStatus, oopMessage } = useStateContext();
  const { data, isLoading, error, setPage, pageCount, setSearch, refetch } =
    useHookSearch();
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [filterBy, setFilterBy] = useState(false);
  const [searchComponet, setSearchComponent] = useState(false);
  const [requestToken] = useState(process.env.REACT_APP_ACCESS_TOKEN);
  const [comicData, setComicData] = useState([]);
  const handleSearch = (searchValue) => {
    setFilterBy(false);
    setSearch(searchValue);
  };
  const handlefilterBy = (ture, filterByValu) => {
    if (ture) {
      setFilterBy(true);
    } else {
      setFilterBy(false);
    }
  };

  useEffect(() => {
    if (popupData.isVisible || searchComponet) {
      document.body.style.overflowY = "hidden"; // Allow scrolling
    } else {
      document.body.style.overflowY = "auto"; // Prevent scrolling
    }

    // Cleanup function to reset the overflow style
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [popupData.isVisible, searchComponet]);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);

    // Disable right-click and certain keyboard shortcuts
    const handleContextMenu = (e) => e.preventDefault();
    const handleKeyDown = (e) => {
      if (
        e.keyCode === 123 || // F12
        (e.ctrlKey && e.shiftKey && (e.keyCode === 73 || e.keyCode === 74)) || // Ctrl+Shift+I or Ctrl+Shift+J
        (e.ctrlKey && e.keyCode === 85)
      ) {
        // Ctrl+U
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div id="body-wrapper">
      <header className="fixed top-0 left-0 w-screen  z-50">
        <Navbar
          onMenuSwich={handleSearch}
          handlefilterBy={handlefilterBy}
          setSearch={setSearchComponent}
          token={token}
          user={user}
        />
      </header>
      <DetailVideo />
      <main
        className={`body-wrapper pt-20 md:pt-20 py-3 max-w-screen-2xl mx-auto`}
      >
        {searchComponet ? (
          <Search
            data={data}
            refetch={refetch}
            isLoading={isLoading}
            error={error}
            setPage={setPage}
            pageCount={pageCount}
          />
        ) : (
          ""
        )}
        <div className="px-5 md:px-20 xl:px-0">
          {oopStatus ? <Oop message={oopMessage} /> : <Outlet />}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default Index;
