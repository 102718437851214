import { useQuery } from "@tanstack/react-query";
import { GetComicAxios } from "../Appcontrollers/comicXciosClient";
import { useParams } from "react-router-dom";

export const useHookView = () => {

    const { id, title, ep }= useParams();

    const fetchMovieDetail = async ({ queryKey }) => {
        const [_key, id, title, ep] = queryKey;
        const response = await GetComicAxios.post('/movie/detail', {
            id: id,
            title: title,
            ep: ep
        });
        console.log(response)
        return response.data.success;
    };

    const { data, isLoading, error, refetch } = useQuery({
        queryKey: ["movie-detail", id, title, ep],
        queryFn: fetchMovieDetail,
        enabled: !!id && !!title && !!ep,  // Ensure query is only enabled if id, title, and ep are defined
        staleTime: 1000,
    });

    return { data, isLoading, error, refetch };
}