const Footer = () => {
  return (
    <div className="w-full bg-dark mt-5 bg-blue-900">
      <footer className="py-3 mx-auto max-w-5xl">
        <p className=" px-5 text-center text-white">
          본 사이트는 어떠한 동영상이나 파일도 직접적으로 업로드하거나 제공하지
          않습니다. 단지 웹 서핑을 통해 다양한 페이지의 위치를 하이퍼링크,
          다이렉트 링크, 직접 링크 등의 형태로 수집하여 제공할 뿐입니다. 수집된
          각각의 주소는 해당 페이지가 제공하는 서버나 사이트의 상황에 따라
          삭제되거나 중단될 수 있으며, 이러한 변경 사항은 본 사이트와 아무런
          연관이 없음을 알려드립니다.
        </p>
        <p className="text-center text-blue-400 mt-3">
          SharkTV © 2023. All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default Footer;
