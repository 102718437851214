import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ComicCard from "../components/comicCard/Index";
import FetchingDataError from "../components/fetchingError/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { GetComicAxios } from "../Appcontrollers/comicXciosClient";

function Home() {
  const [data, setData] = useState([]);
  const [lastes, setLates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const [moviesResponse, latesResponse] = await Promise.all([
          GetComicAxios.get(
            "https://moviesapi.m27.shop/api/movies/category/in-cate?limit=12"
          ),
          GetComicAxios.get(
            "https://moviesapi.m27.shop/api/movies/lastes?limit=12"
          ),
        ]);

        setData(moviesResponse.data["category"]);
        setLates(latesResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error);
        setLoading(false);
      }
    };

    fetchMovies();
  }, []);
  const translateCategory = (category) => {
    switch (category) {
      case "k-drama":
        return "드라마";
      case "k-movies":
        return "한국영화";
      case "foreign":
        return "해외영화";
      case "animation-movies":
        return "애니메이션";
      case "entertainment":
        return "TV/예능";
      default:
        return category;
    }
  };

  const translateCategoryPath = (category) => {
    switch (category) {
      case "k-drama":
        return "/drama";
      case "k-movies":
        return "/korean-movies";
      case "foreign":
        return "/international-movies";
      case "animation-movies":
        return "/animated-film";
      case "entertainment":
        return "/entertainment";
      default:
        return `/${category}`;
    }
  };

  const renderMovies = (category) => {
    const movies = data[category];

    if (!Array.isArray(movies)) return null;

    return movies.map((movie) => (
      <li key={movie.title_id} className="comic-card-item">
        <ComicCard
          key={movie.comic_title_id}
          title={movie.title}
          poster={movie.image}
          id={movie.title_id}
          year={movie.release_date}
          genreId={movie.cate_id}
          ep={movie.number_ep || 0}
          disciption={movie.des}
        />
      </li>
    ));
  };

  const renderCategories = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <FetchingDataError error={error.message} />;
    }

    if (!data || typeof data !== "object") {
      return null;
    }

    return Object.keys(data).map((category) => (
      <div key={category}>
        <div className="flex flex-row  items-center justify-between">
          <h2 className="genre-title pb-4">
            <Link to={translateCategoryPath(category)}>
              {translateCategory(category)}
              <FontAwesomeIcon className="pl-2" icon={faArrowAltCircleRight} />
            </Link>
          </h2>
          <Link to={translateCategoryPath(category)}>전체보기</Link>
        </div>
        <hr />
        <ul className="pt-3 comic_items_wrapper grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-1">
          {renderMovies(category)}
        </ul>
      </div>
    ));
  };

  const renderLatesteMovies = () => {
    if (!Array.isArray(lastes)) return null;

    return lastes.map((movie) => {
      const latestEpDate = new Date(movie.latest_ep_date);
      const currentTime = new Date();
      const timeDifference = currentTime - latestEpDate;

      let postingTime;

      if (timeDifference < 24 * 60 * 60 * 1000) {
        // Less than one day
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );

        if (hours === 0 && minutes <= 1) {
          postingTime = "new";
        } else if (hours > 0) {
          postingTime = `new`;
        } else {
          postingTime = `new`;
        }
      } else {
        postingTime = "new";
      }

      return (
        <li key={movie.title_id} className="comic-card-item">
          <ComicCard
            key={movie.comic_title_id}
            title={movie.title}
            poster={movie.image}
            id={movie.title_id}
            year={movie?.latest_ep_date}
            posting_titme={postingTime}
            genreId={movie.cate_id}
            ep={movie.number_ep || 0}
            disciption={movie.des}
          />
        </li>
      );
    });
  };

  return (
    <div className="comic-card-wrapper">
      <div>
        <h2 className="genre-title pb-4">최신VOD</h2>
        <hr />
        <ul className="pt-3 comic_items_wrapper grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-6 gap-1">
          {renderLatesteMovies()}
        </ul>
      </div>
      {renderCategories()}
    </div>
  );
}

export default Home;
