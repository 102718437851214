import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { useDetailPop } from "../../Appcontrollers/videoDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faPlay, faSync } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ActorSlider } from "../Artor_slider";
import { GetComicAxios } from "../../Appcontrollers/comicXciosClient";
import Epesod from "../ep/epoesond";
const DetailVideo = () => {
  const { popupData, hidePopup } = useDetailPop();
  const [movieData, setMovieData] = useState();

  useEffect(() => {
    const getAuthor = async () => {
      try {
        const response = await GetComicAxios.post("/movie/detail", {
          id: popupData.id,
          title: popupData.title,
          ep: popupData.ep,
        });
        setMovieData(response.data.success); // Handle the response data as needed
      } catch (error) {
        console.error("Error fetching author data:", error);
      }
    };

    if (popupData.id) {
      getAuthor();
    }
  }, [popupData.id]);
  if (!popupData.isVisible) return null;

  let actors = [];
  if (movieData) {
    if (typeof movieData?.actor === "string") {
      if (
        movieData.actor.trim().startsWith("[") &&
        movieData.actor.trim().endsWith("]")
      ) {
        try {
          actors = JSON.parse(movieData.actor);
        } catch (error) {
          actors = [];
        }
      } else {
        // handle case where actor data is not a valid JSON string
      }
    } else if (Array.isArray(movieData?.actor)) {
      actors = movieData.actor;
    }
  }

  return (
    <div className="popup-overlay relative">
      <div className="popup-content overflow-y-auto overflow-x-hidden">
        <div className="flex flex-col sm:flex-row items-start justify-start sm:items-center gap-5 mx-auto relative">
          <button
            onClick={hidePopup}
            className=" absolute -top-8 -right-8 bg-blue-950 text-white w-12 h-12 rounded-full"
          >
            <FontAwesomeIcon icon={faClose} />
          </button>
          <div className="h-64 w-full sm:w-full ">
            <img src={popupData.poster} className="h-64 w-56 mx-auto" />
          </div>
          <div className="text-justify w-full sm:w-full">
            <h2 className="mb-4 font-bold">{popupData.title}</h2>
            <p className=" font-light">{popupData.disciption}</p>
            <div className="flex flex-row items-center justify-start gap-4 mt-2">
              <button
                onClick={hidePopup}
                className=" bg-red-400 text-white py-2 flex flex-row items-center justify-start px-4 gap-3 rounded-full"
              >
                <FontAwesomeIcon icon={faClose} />
                <span>닫기</span>
              </button>
              <Link to={`/view/${popupData.id}/view/${popupData.ep}`}>
                <button className=" bg-blue-950 text-white py-2 flex flex-row items-center justify-start px-4 gap-3 rounded-full">
                  <FontAwesomeIcon icon={faPlay} />
                  재생
                </button>
              </Link>
            </div>
          </div>
        </div>
        {popupData.year && (
          <div className="px-4 py-1 my-3 min-w-40 bg-blue-950 text-white">
            {popupData.year}
          </div>
        )}

        {popupData.ep > 0 && (
          <>
            <h2>에피소드:</h2>
            <Epesod title={popupData.title} title_id={popupData.id} />
          </>
        )}
        {Array.isArray(actors) && actors.length > 0 ? (
          <ActorSlider actors={movieData?.actor_images} />
        ) : (
          <span className="tag" style={{ color: "white" }}>
            {movieData?.actor}
          </span>
        )}
      </div>
    </div>
  );
};

export default DetailVideo;
